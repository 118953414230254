import { popup, ButtonType, ButtonResult, ButtonGroup } from "ui";

const translationState = (window as any).app.preloadState.translation;
const availableSections = (window as any).app.preloadState.availableSections;
const isLoggedIn = (window as any).app.preloadState.isLoggedIn;

function App() {

    const sectionItems = availableSections
        .map((section: any) => ({
            label: translationState[section.type === 0 ? "navigationTypes.standardViewLabel" : "navigationTypes.explodedViewLabel"],
            value: section,
            isVisible: section.isAvailable
        }));

    const initialItem = availableSections.find((s: any) => s.isSelected);

    const updateNavigationMode = async (url: string, isExplodedView: boolean) => {
        if (!isLoggedIn && isExplodedView) {
            showFreightPopup();
        } else {
            await fetch(`/api/user/selected-exploded-view`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
                },
                body: isExplodedView ? "true" : "false",
                method: "PUT"
            });
            location.assign(url);
        }
    }

    const showFreightPopup = () => {
        popup(
            translationState["logInPopUp.title"],
            translationState["logInPopUp.body"],
            [
                { type: ButtonType.Primary, label: translationState["logInPopUp.buttonLabel"], result: ButtonResult.Ok }
            ]
        );
    }

    const onChecked = (section: any) => updateNavigationMode(section.contentUrl, section.type === 1);

    return <ButtonGroup
        initialSelected={initialItem}
        onChecked={onChecked}
        items={sectionItems}
    />
}

export default App;
